import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Services from '../components/services'

const ServicesPage = () => {
  return (
    <Layout pageTitle="Services">
      <Services />
    </Layout>
  )
}

export const Head = () => <Seo title="Services" />

export default ServicesPage