// extracted by mini-css-extract-plugin
export var bannerDescription = "services-module--banner-description--BDTUJ";
export var bannerHeading = "services-module--banner-heading--RqbJ-";
export var bannerLogo = "services-module--banner-logo--4ti1z";
export var greenText = "services-module--green-text--GV+5x";
export var serviceBanner = "services-module--service-banner--x+0eY";
export var servicesBannerContainer = "services-module--services-banner-container--1oXUj";
export var servicesBannerRow = "services-module--services-banner-row--ComWr";
export var servicesContainer = "services-module--services-container--pVvM5";
export var servicesHeading = "services-module--services-heading--lYAAM";
export var servicesLabel = "services-module--services-label--cOzN3";